<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    isEmbed: {
      type: Boolean,
      default: false,
    },
    hasPrice: {
      type: Boolean,
      default: true,
    },
    trainingName: {
      type: String,
      default: null,
    },
    training: {
      type: Object,
      required: true,
    },
    isBuyable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    href() {
      return this.$router
        .resolve({ name: 'training', params: { slug: this.training.slug } })
        .href;
    },
    priceStyle() {
      return {
        'font-weight': this.training.discount_price != null && 400,
        'text-decoration': this.training.discount_price != null && 'line-through',
        opacity: this.training.discount_price != null && 0.7,
      };
    },
    picture() {
      if (this.training.picture && !this.training.picture.startsWith('http')) {
        return `${this.$env.apiResourcesURL}/${this.training.picture}`;
      }

      if (!this.training.picture) {
        this.$emit('img-load');
      }

      return this.training.picture;
    },
  },
  methods: {
    onClick() {
      if (!this.isBuyable) {
        return;
      }

      if (this.isEmbed) {
        window.parent.postMessage({ name: 'checkout' }, '*');
        return;
      }

      this.$router.push({ name: 'training', params: { slug: this.training.slug } });
    },
  },
};
</script>

<template>
  <article class="comp box">
    <div class="columns is-vcentered">
      <div class="column is-narrow">
        <figure
          class="comp_img image bradius-8"
          :class="{'cursor-pointer': isBuyable}"
          @click.prevent="onClick"
        >
          <img
            v-if="picture"
            :src="picture"
            :alt="trainingName || training.name"
            @load="$emit('img-load')"
          >
          <span v-else class="is-block p-10 has-background-light is-custom has-text-centered">
            <b-icon icon="chalkboard-teacher" size="is-large" />
          </span>
        </figure>
      </div>
      <div class="column">
        <h2 class="title is-custom is-4 is-size-6-touch">
          <component :is="isBuyable ? 'a' : 'span'"
            class="color-inherit"
            :href="href"
            @click.prevent="onClick"
          >
            {{ trainingName || training.name }}
        </component>
        </h2>
        <p v-if="training.summary" class="is-size-6 is-size-8-touch">
          {{ training.summary }}
        </p>
        <p v-if="isBuyable" class="mt-4 is-size-6 is-size-8-touch">
          <template v-if="hasPrice">
            <strong v-if="training.discount_price != null" class="mr-3 valign-middle">
              <template v-if="training.discount_price > 0">
                {{ training.discount_price | formatPrice }}
              </template>
              <template v-else>
                Gratuit
              </template>
            </strong>
            <strong class="mr-3 valign-middle" :style="priceStyle">
              <template v-if="training.price > 0">
                {{ training.price | formatPrice }}
              </template>
              <template v-else>
                Gratuit
              </template>
            </strong>
          </template>
          <b-button
            type="is-primary is-custom valign-middle"
            tag="a"
            :href="href"
            @click.prevent="onClick"
          >
            Voir la formation
          </b-button>
        </p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.comp {
  &_img {
    width: 320px;
    max-height: 320px;

    img {
      object-fit: cover;
      object-position: center top;
    }

    .is-small & {
      width: 200px;
    }

    @include mobile {
      &,
      .is-small & {
        width: 100%;
      }
    }
  }

  .button {
    white-space: initial;

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
