import Vue from 'vue';
import Vuex from 'vuex';
import * as errors from '@shared/config/store/errors';
import * as community from '@shared/config/store/community';
import * as auth from './auth';
import * as store from './store';
import * as header from './header';
import * as trainings from './trainings';

Vue.use(Vuex);

const modules = {
  errors,
  community,
  auth,
  store,
  header,
  trainings,
};

Object.keys(modules)
  .forEach((key) => (modules[key].namespaced = true));

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
});
