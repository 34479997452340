import { storeApi } from '@shared/config/axios';

export default {
  register(data) {
    return storeApi.$post('/register', data);
  },
  update(data) {
    return storeApi.$put('/customers/me', data);
  },
  delete(data) {
    return storeApi.$put('/customers/me/delete', data);
  },
  updatePassword(data) {
    return storeApi.$put('/customers/me/password', data);
  },
  updateLastLogin() {
    return storeApi.$put('/customers/me/last_login');
  },
  getNotifications(page = 1) {
    return storeApi.$get(`/customers/me/notifications?page=${page}`);
  },
  updateNotification(notifId, data) {
    return storeApi.$put(`/customers/me/notifications/${notifId}`, data);
  },
  clearNotifications() {
    return storeApi.$put('/customers/me/notifications/read');
  },
  updatePaymentMethod(data) {
    return storeApi.$put('/customers/me/payments/methods', data);
  },
  finalizePaymentMethod(data) {
    return storeApi.$put('/customers/me/payments/methods/finalization', data);
  },
  askNewPassword(email) {
    return storeApi.$put('/passwords/reset', { email });
  },
  resetPassword(data) {
    return storeApi.$put('/passwords/reset', data);
  },
  sendVerificationEmail(email) {
    return storeApi.$post('/emails/resend', { email });
  },
  payment(data) {
    return storeApi.$post('/payments/intents', data);
  },
  getPayPalOrder(data) {
    return storeApi.$post('/payments/paypal', data);
  },
  confirmPayPalOrder(data) {
    return storeApi.$put('/payments/paypal', data);
  },
  updateEnrollment(data) {
    return storeApi.put('/enrollment', data)
      .then(({ headers, status, data }) => ({ headers, status, data }));
  },
  buy(data) {
    return storeApi.put('/buy', data)
      .then(({ headers, status, data }) => ({ headers, status, data }));
  },
  getTraining(slug, token) {
    return storeApi.$get(`/customers/me/trainings/${slug}`, { params: { token } });
  },
  getTrainingComments(slugOrURL, params) {
    if (slugOrURL.startsWith('http')) {
      return storeApi.$get(slugOrURL);
    }
    return storeApi.$get(`/customers/me/trainings/${slugOrURL}/comments`, { params });
  },
  getTrainingItem(slug, id, token) {
    return storeApi.$get(`/customers/me/trainings/${slug}/training_items/${id}`, { params: { token } });
  },
  getTrainingItemComments(slugOrURL, id, params) {
    if (slugOrURL.startsWith('http')) {
      return storeApi.$get(slugOrURL);
    }
    return storeApi.$get(`/customers/me/trainings/${slugOrURL}/training_items/${id}/comments`, {
      params,
    });
  },
  addTrainingItemComment(slug, id, data) {
    return storeApi.$post(`/customers/me/trainings/${slug}/training_items/${id}/comments`, data);
  },
  deleteTrainingItemComment(slug, itemId, id) {
    return storeApi.$delete(
      `/customers/me/trainings/${slug}/training_items/${itemId}/comments/${id}`,
    );
  },
  startTrainingItemSession(slug, itemId) {
    return storeApi.$post(`/customers/me/trainings/${slug}/training_items/${itemId}/progressions/sessions`);
  },
  updateTrainingItemSession(slug, itemId, sessionId, data) {
    return storeApi.$put(`/customers/me/trainings/${slug}/training_items/${itemId}/progressions/sessions/${sessionId}`, data);
  },
  updateTrainingItemProgression(slug, itemId, data) {
    return storeApi.$put(`/customers/me/trainings/${slug}/training_items/${itemId}/progressions`, data);
  },
  getQuizResponse(slug, itemId) {
    return storeApi.$get(`/customers/me/trainings/${slug}/training_items/${itemId}/quiz`);
  },
  answerQuiz(slug, itemId, data) {
    return storeApi.$post(`/customers/me/trainings/${slug}/training_items/${itemId}/quiz`, data);
  },
  startTrainingItemAssignments(slug, id) {
    return storeApi.$post(`/customers/me/trainings/${slug}/training_items/${id}/assignments`);
  },
  getTrainingItemAssignmentSubmission(slug, id) {
    return storeApi.$get(`/customers/me/trainings/${slug}/training_items/${id}/assignments`);
  },
  submitTrainingItemAssignments(slug, id, assignmentId, data) {
    return storeApi.$put(`/customers/me/trainings/${slug}/training_items/${id}/assignments/${assignmentId}`, data);
  },
  deleteTrainingItemAssignmentSubmissionPath(slug, id, assignmentId, pathId) {
    return storeApi.$delete(`/customers/me/trainings/${slug}/training_items/${id}/assignments/${assignmentId}/paths/${pathId}`);
  },
  addTrainingItemAssignmentComment(slug, id, assignmentId, data) {
    return storeApi.$post(`/customers/me/trainings/${slug}/training_items/${id}/assignments/${assignmentId}/comments`, data);
  },
  generateCertificate(slug) {
    return storeApi.$get(`/customers/me/trainings/${slug}/certificate`);
  },
  joinAffiliates() {
    return storeApi.$post('/customers/me/affiliates/join');
  },
  getAffiliateData() {
    return storeApi.$get('/customers/me/affiliates');
  },
  askAffiliatePayout(data) {
    return storeApi.$post('/customers/me/affiliates/payout', data);
  },
  getAffiliateSales({ url, params }) {
    if (url) {
      return storeApi.$get(url);
    }

    return storeApi.$get('/customers/me/affiliates/sales', {
      params,
    });
  },
};
