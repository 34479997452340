let $header;

export const state = () => ({
  height: null,
});

export const mutations = {
  setElement(state, el) {
    $header = el;
    state.height = $header.clientHeight;
  },
  refresh(state) {
    if (!$header) {
      return;
    }

    state.height = $header.clientHeight;
  },
};
