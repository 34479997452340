import { storeApi } from '@shared/config/axios';

export default {
  getStore() {
    return storeApi.$get('');
  },
  getPricingData(params) {
    return storeApi.$get('/pricings', { params });
  },
  verifyEmail({ email, t }) {
    return storeApi.$get(`/emails/verification?email=${email}&token=${t}`);
  },
};
