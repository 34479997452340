import APICustomer from '@school/services/API/Customer';
import { getItemsGroupedByType } from '@shared/services/Utils';

export const state = () => ({
  current: null,
});

export const mutations = {
  setCurrent(state, training) {
    state.current = training;
  },
  updateItemInCurrent(state, trainingItem) {
    if (!state.current) {
      return;
    }

    if (trainingItem.parent_id) {
      state.current.items = state.current.items.map((item) => {
        if (item.id === trainingItem.parent_id) {
          if (item.children) {
            item.children = item.children.map(
              (v) => (v.id === trainingItem.id ? trainingItem : v),
            );
          }
        }

        return item;
      });
    } else {
      state.current.items = state.current.items.map(
        (v) => (v.id === trainingItem.id ? trainingItem : v),
      );
    }
  },
};

export const actions = {
  find({ state, getters, dispatch }, { slug, token }) {
    if (state.current && state.current.slug === slug) {
      return {
        training: state.current,
        itemsGroupedByType: getters.currentItemsGroupedByType,
      };
    }

    return dispatch('forceFind', { slug, token });
  },
  forceFind({ commit, state, getters }, { slug, token }) {
    return APICustomer.getTraining(slug, token)
      .then((data) => {
        commit('setCurrent', data.data);
        return {
          training: state.current,
          itemsGroupedByType: getters.currentItemsGroupedByType,
        };
      });
  },
};

export const getters = {
  currentItemsFlatArray(state) {
    return state.current.items.reduce((prev, curr) => {
      if (curr.type === 'SECTION') {
        return [...prev, ...curr.children];
      }

      prev.push(curr);
      return prev;
    }, []);
  },
  currentItemsGroupedByType(state) {
    return getItemsGroupedByType(state.current.items);
  },
};
