<script>
import TrainingItem from '@school/components/TrainingItem.vue';

export default {
  components: { TrainingItem },
  props: {
    total: {
      type: Number,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    hasPrice: {
      type: Boolean,
      default: true,
    },
    trainings: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <section>
    <h2 v-if="hasTitle" class="title is-custom is-size-5-touch">
      Formations
    </h2>

    <p v-if="total <= 0" class="box">
      Il n'y a pas encore de formation !
    </p>
    <template v-else-if="trainings.length">
      <TrainingItem v-for="one in trainings" :key="one.uuid" :class="{ 'is-small': isSmall }" :training="one"
        :hasPrice="hasPrice" />
    </template>
    <p v-else class="box">
      Aucune formation ne correspond à votre recherche.
    </p>
  </section>
</template>
