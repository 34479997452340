<script>
import BundleItem from '@school/components/BundleItem.vue';

export default {
  components: { BundleItem },
  props: {
    total: {
      type: Number,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    hasPrice: {
      type: Boolean,
      default: true,
    },
    bundles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <section>
    <h2 v-if="hasTitle" class="title is-custom is-size-5-touch">
      Packs
    </h2>

    <p v-if="total <= 0" class="box">
      Il n'y a pas encore de packs !
    </p>
    <template v-else-if="bundles.length">
      <BundleItem v-for="bundle in bundles" :key="bundle.uuid" v-bind="{ bundle, hasPrice }"
        :class="{ 'is-small': isSmall }" />
    </template>
    <p v-else class="box">
      Aucune pack ne correspond à votre recherche.
    </p>
  </section>
</template>
