import { storeApi } from '@shared/config/axios';

export default {
  register(data) {
    return storeApi.post('/affiliates/register', data);
  },
  trackVisit(params) {
    return storeApi.$get('/affiliates/visits', { params });
  },
};
