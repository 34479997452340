import get from 'lodash/get';
import { env } from '@shared/env';
// import http from '@school/config/axios';
import theme from '@shared/assets/styles/theme';
import { hexToRGBA } from '@shared/services/Utils';
import APIStore from '@school/services/API/Store';

const data = get(window, 'initialState.store', null);

// if (data) {
//   http.setStore(data.subdomain);
// }

const setApiResourcesURL = (store) => {
  const { logo } = store;
  const { favicon } = store;

  if (logo && !logo.startsWith('http')) {
    store.logo = `${env.apiResourcesURL}/${logo}`;
  }

  if (favicon && !favicon.startsWith('http')) {
    store.favicon = `${env.apiResourcesURL}/${favicon}`;
  }

  if (get(store, 'seo_data.image') && !store.seo_data.image.startsWith('http')) {
    store.seo_data.image = `${env.apiResourcesURL}/${store.seo_data.image}`;
  }

  return store;
};

export const state = () => ({
  data: setApiResourcesURL(data),
});

export const mutations = {
  setStore(state, data) {
    state.data = setApiResourcesURL(data);
  },
};

export const actions = {
  fetch({ commit }) {
    return APIStore.getStore()
      .then(({ data }) => {
        commit('setStore', data);
      });
  },
};

export const getters = {
  brandColor: (state) => state.data.brand_color,
  btnColor: (state) => state.data.button_color || state.data.brand_color,
  btnTextColor: (state) => {
    if (state.data.button_text_color) {
      return state.data.button_text_color;
    }

    if (state.data.brand_color) {
      return theme.color.white;
    }

    return null;
  },
  headingColor: (state) => state.data.heading_color || state.data.brand_color,
  textColor: (state) => state.data.text_color,
  bgColor: (state) => state.data.background_color,
  heroColor: (state) => {
    const color = state.data.brand_color || theme.color.primary;
    return hexToRGBA(color, 0.1);
  },
  communityBgColor: (state) => {
    const color = state.data.background_color || theme.color.grey_light2;
    return hexToRGBA(color, 0.1);
  },
  hasProPlan(state) {
    return !!state.data && state.data.plan === 'PRO';
  },
  hasPerkPlan(state) {
    return !!state.data && state.data.plan === 'PERK';
  },
  hasExpertPlan(state) {
    return !!state.data && state.data.plan === 'EXPERT';
  },
  hasPercentPlan(state) {
    return !!state.data && state.data.plan === 'PERCENT';
  },
  isNotPercentPlan(state) {
    return !!state.data && state.data.plan !== 'PERCENT';
  },
  isNotRecurringPlan(state, getters) {
    return getters.hasPercentPlan || getters.hasPerkPlan;
  },
  trainingsOrderByUuid(state) {
    return !state.data.trainings_order ? {} : state.data.trainings_order
      .reduce((prev, curr) => {
        prev[curr.uuid] = curr.order;
        return prev;
      }, {});
  },
  isAffiliationClosed(state) {
    return state.data.affiliation_options.status === 'CLOSED';
  },
  isAffiliationPublic(state) {
    return state.data.affiliation_options.status === 'PUBLIC';
  },
  isAffiliationPrivate(state) {
    return state.data.affiliation_options.status === 'PRIVATE';
  },
};
