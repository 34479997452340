<script>
import StoreHero from '@school/components/StoreHero.vue';
import TrainingsList from '@school/components/TrainingsList.vue';
import TeacherSection from '@school/components/TeacherSection.vue';
import BundlesList from '@school/components/BundlesList.vue';
import { isEqual } from 'lodash';

const DEFAULT_SEARCH_FORM_DATA = {
  q: '',
  sort_by: '',
  order_by: 'desc',
};

export default {
  name: 'Home',
  components: {
    StoreHero,
    TrainingsList,
    TeacherSection,
    BundlesList,
  },
  head() {
    return {
      title: this.store.name,
      titleTemplate: null,
    };
  },
  data: () => ({
    searchFormData: {
      q: '',
      sort_by: '',
      order_by: 'desc',
    },
  }),
  computed: {
    searchParamsDirty() {
      return !isEqual(DEFAULT_SEARCH_FORM_DATA, this.searchFormData);
    },
    store() {
      return this.$store.state.store.data;
    },
    filteredTrainings() {
      let value = [...this.store.trainings];

      if (this.searchFormData.q) {
        value = value
          .filter((training) => training.name?.toLowerCase().includes(this.searchFormData.q.toLowerCase())
            || training.description?.toLowerCase().includes(this.searchFormData.q.toLowerCase())
            || training.summary?.toLowerCase().includes(this.searchFormData.q.toLowerCase()));
      }

      if (this.searchFormData.sort_by === 'price') {
        value = value.sort((a, b) => {
          const aPrice = a.discount_price || a.price;
          const bPrice = b.discount_price || b.price;

          if (this.searchFormData.order_by === 'desc') {
            return bPrice - aPrice;
          }

          return aPrice - bPrice;
        });
      }

      if (this.searchFormData.sort_by === 'name') {
        value = value.sort((a, b) => {
          if (this.searchFormData.order_by === 'desc') {
            return b.name.localeCompare(a.name);
          }
          return a.name.localeCompare(b.name);
        });
      }

      return value;
    },
    filteredBundles() {
      let value = [...this.store.bundles];

      if (this.searchFormData.q) {
        value = value
          .filter((bundle) => bundle.name?.toLowerCase().includes(this.searchFormData.q.toLowerCase())
            || bundle.description?.toLowerCase().includes(this.searchFormData.q.toLowerCase())
            || bundle.summary?.toLowerCase().includes(this.searchFormData.q.toLowerCase()));
      }

      if (this.searchFormData.sort_by === 'price') {
        value = value.sort((a, b) => {
          const aPrice = a.discount_price || a.price;
          const bPrice = b.discount_price || b.price;

          if (this.searchFormData.order_by === 'desc') {
            return bPrice - aPrice;
          }

          return aPrice - bPrice;
        });
      }

      if (this.searchFormData.sort_by === 'name') {
        value = value.sort((a, b) => {
          if (this.searchFormData.order_by === 'desc') {
            return b.name.localeCompare(a.name);
          }
          return a.name.localeCompare(b.name);
        });
      }

      return value;
    },
  },
  methods: {
    resetSearchParams() {
      return this.searchFormData = { ...DEFAULT_SEARCH_FORM_DATA };
    },
    toggleOrder() {
      this.searchFormData.order_by = this.searchFormData.order_by !== 'desc' ? 'desc' : 'asc';
    },
  },
};
</script>

<template>
  <div>
    <div class="has-background-white">
      <section class="hero is-light is-custom is-medium">
        <div class="hero-body mnh-520 py-20 is-flex is-align-items-center">
          <StoreHero class="flex-none" :store="store" />
        </div>
      </section>
    </div>

    <div class="container">
      <div class="section">
        <div v-if="store.feature_options.pages.research.enabled">
          <div class="columns">
            <div class="column">
              <b-field label="Filtrer par">
                <b-input v-model="searchFormData.q" placeholder="Filtrer par nom ou description" />
              </b-field>
            </div>

            <div class="column is-narrow">
              <b-field label="Trier par">
                <b-select v-model="searchFormData.sort_by">
                  <option value="" disabled>Par défaut</option>
                  <option value="name">Nom</option>
                  <option value="price">Prix</option>
                </b-select>
                <p class="control">
                  <b-tooltip :label="searchFormData.order_by == 'asc' ? 'croissant' : 'décroissant'">
                    <b-button :icon-left="searchFormData.order_by == 'asc' ? 'arrow-up' : 'arrow-down'"
                      @click="toggleOrder" />
                  </b-tooltip>
                </p>
              </b-field>
            </div>
          </div>

          <a v-if="searchParamsDirty" href="#" class="is-size-8 level-right mb-6" @click.prevent="resetSearchParams"
            style="cursor:pointer;">
            <strong>Réinitialiser les paramètres de recherche</strong>
          </a>
        </div>

        <div v-if="store.bundles.length">
          <BundlesList class="mt-5 is-medium" :bundles="filteredBundles" :total="store.bundles.length" />
        </div>

        <TrainingsList class="mt-5 is-medium" :trainings="filteredTrainings" :total="store.trainings.length" />
      </div>
    </div>

    <!-- <div class="container">
      <h2 class="title is-custom is-size-5-touch">
        Packs
      </h2>
      <div v-for="bundle in store.bundles" :key="bundle.uuid">
        {{ bundle.name }}
      </div>
    </div> -->

    <TeacherSection />
  </div>
</template>
